.navBarView {
  background-color: #c6c6c6;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
  z-index: 20;
}
.logoView {
  height: 50px;
}

.offcanvasView {
  width: 300px !important;
  background-color: #959595;
}

.navLinkText {
  color: white;
  font-family: "微軟正黑體";
  font-weight: bold;
}

@media screen and (min-width: 1400px) {
  .logoView {
    width: 150px;
  }
}

/* for screens between 1201px and 1400px */
@media screen and (min-width: 1201px) and (max-width: 1399px) {
  .logoView {
    width: 150px;
  }
}

/* for screens between 993px and 1199px */
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .logoView {
    width: 150px;
  }
}

/* for screens between 769px and 992px */
@media screen and (min-width: 769px) and (max-width: 992px) {
  .logoView {
    width: 150px;
  }
}

/* for screens between 576px and 768px */
@media screen and (min-width: 576px) and (max-width: 768px) {
  .logoView {
    width: 150px;
  }
}

/* for screens smaller than 576px */
@media screen and (max-width: 575px) {
  .logoView {
    width: 100px;
  }
}
