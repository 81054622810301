body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.homepage_text__3gOpq {
  color: red;
}

@media screen and (min-width: 1400px) {
  .homepage_logoView__bHdOf {
    width: 150px;
  }

  .homepage_carousel__3lxXu {
    width: 80%;
  }

  .homepage_carouselImg__1ZPzd {
    width: 1274px;
  }
}

/* for screens between 1200px and 1400px */
@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .homepage_logoView__bHdOf {
    width: 150px;
  }

  .homepage_carousel__3lxXu {
    width: 80%;
  }

  .homepage_carouselImg__1ZPzd {
    width: 1274px;
  }
}

/* for screens between 576px and 1199px */
@media screen and (min-width: 576px) and (max-width: 1199px) {
  .homepage_logoView__bHdOf {
    width: 150px;
  }

  .homepage_carousel__3lxXu {
    width: 100%;
  }

  .homepage_carouselImg__1ZPzd {
    width: 750px;
  }
}

/* for screens smaller than 576px */
@media screen and (max-width: 575px) {
  .homepage_logoView__bHdOf {
    width: 100px;
  }

  .homepage_carousel__3lxXu {
    width: 100%;
  }

  .homepage_carouselImg__1ZPzd {
    width: 100vw;
  }
}

.layout_main__OYktE {
  width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (min-width: 1400px) {
  .layout_productPage__3UULr {
    width: 78%;
    margin-top: 50px;
  }
}

/* for screens between 1201px and 1400px */
@media screen and (min-width: 1201px) and (max-width: 1399px) {
  .layout_productPage__3UULr {
    width: 78%;
    margin-top: 50px;
  }
}

/* for screens between 993px and 1199px */
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .layout_productPage__3UULr {
    width: 78%;
    margin-top: 50px;
  }
}

/* for screens between 769px and 992px */
@media screen and (min-width: 769px) and (max-width: 992px) {
  .layout_productPage__3UULr {
    width: 100%;
    margin-top: 30px;
  }
}

/* for screens between 576px and 768px */
@media screen and (min-width: 576px) and (max-width: 768px) {
  .layout_productPage__3UULr {
    width: 100%;
    margin-top: 30px;
  }
}

/* for screens smaller than 576px */
@media screen and (max-width: 575px) {
  .layout_productPage__3UULr {
    width: 100%;
    margin-top: 30px;
  }
}

.navbar_navBarView__1GuA_ {
  background-color: #c6c6c6;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
  z-index: 20;
}
.navbar_logoView__2SzJc {
  height: 50px;
}

.navbar_offcanvasView__Aeayc {
  width: 300px !important;
  background-color: #959595;
}

.navbar_navLinkText__IFD0f {
  color: white;
  font-family: "\5FAE\8EDF\6B63\9ED1\9AD4";
  font-weight: bold;
}

@media screen and (min-width: 1400px) {
  .navbar_logoView__2SzJc {
    width: 150px;
  }
}

/* for screens between 1201px and 1400px */
@media screen and (min-width: 1201px) and (max-width: 1399px) {
  .navbar_logoView__2SzJc {
    width: 150px;
  }
}

/* for screens between 993px and 1199px */
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .navbar_logoView__2SzJc {
    width: 150px;
  }
}

/* for screens between 769px and 992px */
@media screen and (min-width: 769px) and (max-width: 992px) {
  .navbar_logoView__2SzJc {
    width: 150px;
  }
}

/* for screens between 576px and 768px */
@media screen and (min-width: 576px) and (max-width: 768px) {
  .navbar_logoView__2SzJc {
    width: 150px;
  }
}

/* for screens smaller than 576px */
@media screen and (max-width: 575px) {
  .navbar_logoView__2SzJc {
    width: 100px;
  }
}

.footer_hrStyle__3ieYn {
  border: none;
  height: 1px;
  background-color: #000;
}

.footer_textStyle__1IGx7 {
  font-weight: bold;
  font-family: "\5FAE\8EDF\6B63\9ED1\9AD4";
}

.footer_contentStyle__1ughv {
  font-family: "\5FAE\8EDF\6B63\9ED1\9AD4";
}

.footer_informationView__1GiR0 {
  width: 100%;
}

@media screen and (min-width: 1400px) {
  .footer_textStyle__1IGx7 {
    font-size: 28px;
  }

  .footer_contentStyle__1ughv {
    font-size: 18px;
  }

  .footer_footerStyle__1yY4V {
    width: 75%;
  }
}

/* for screens between 1201px and 1400px */
@media screen and (min-width: 1201px) and (max-width: 1399px) {
  .footer_textStyle__1IGx7 {
    font-size: 28px;
  }

  .footer_contentStyle__1ughv {
    font-size: 18px;
  }

  .footer_footerStyle__1yY4V {
    width: 75%;
  }
}

/* for screens between 993px and 1199px */
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .footer_textStyle__1IGx7 {
    font-size: 28px;
  }

  .footer_contentStyle__1ughv {
    font-size: 18px;
  }

  .footer_footerStyle__1yY4V {
    width: 75%;
  }
}

/* for screens between 769px and 992px */
@media screen and (min-width: 769px) and (max-width: 992px) {
  .footer_textStyle__1IGx7 {
    font-size: 28px;
  }

  .footer_contentStyle__1ughv {
    font-size: 18px;
  }

  .footer_footerStyle__1yY4V {
    width: 75%;
  }
}

/* for screens between 576px and 768px */
@media screen and (min-width: 576px) and (max-width: 768px) {
  .footer_textStyle__1IGx7 {
    font-size: 18px;
  }

  .footer_contentStyle__1ughv {
    font-size: 12px;
  }

  .footer_footerStyle__1yY4V {
    width: 100%;
  }
}

/* for screens smaller than 576px */
@media screen and (max-width: 575px) {
  .footer_textStyle__1IGx7 {
    font-size: 18px;
  }

  .footer_contentStyle__1ughv {
    font-size: 12px;
  }

  .footer_footerStyle__1yY4V {
    width: 100%;
  }
}

.sidemenu_sideMenu__2hhBj {
  width: 20%;
}

.sidemenu_sideMenuTitle__jLZxC {
  color: #8f8f8f;
  font-weight: bold;
}

.sidemenu_sideMenuItemText__KSQKA {
  font-weight: bold;
}

.abouts_hrStyle__1fxV1 {
  border: none;
  height: 1px;
  background-color: #000;
}

.abouts_textStyle__2YBAC {
  font-weight: bold;
  font-family: "\5FAE\8EDF\6B63\9ED1\9AD4";
}

.abouts_contentStyle__3NGh4 {
  font-family: "\5FAE\8EDF\6B63\9ED1\9AD4";
}

.abouts_informationView__1Hjyn {
  width: 100%;
  margin-top: 80px;
}

.abouts_imgView__2l2QS {
  width: 200px;
}

@media screen and (min-width: 1400px) {
  .abouts_contentView__2L65a {
    width: 75%;
  }

  .abouts_textStyle__2YBAC {
    font-size: 28px;
  }

  .abouts_contentStyle__3NGh4 {
    font-size: 18px;
  }
}

/* for screens between 1201px and 1400px */
@media screen and (min-width: 1201px) and (max-width: 1399px) {
  .abouts_contentView__2L65a {
    width: 75%;
  }

  .abouts_textStyle__2YBAC {
    font-size: 28px;
  }

  .abouts_contentStyle__3NGh4 {
    font-size: 18px;
  }
}

/* for screens between 993px and 1199px */
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .abouts_contentView__2L65a {
    width: 75%;
  }

  .abouts_textStyle__2YBAC {
    font-size: 28px;
  }

  .abouts_contentStyle__3NGh4 {
    font-size: 18px;
  }
}

/* for screens between 769px and 992px */
@media screen and (min-width: 769px) and (max-width: 992px) {
  .abouts_contentView__2L65a {
    width: 75%;
  }

  .abouts_textStyle__2YBAC {
    font-size: 28px;
  }

  .abouts_contentStyle__3NGh4 {
    font-size: 18px;
  }
}

/* for screens between 576px and 768px */
@media screen and (min-width: 576px) and (max-width: 768px) {
  .abouts_contentView__2L65a {
    width: 100%;
  }

  .abouts_textStyle__2YBAC {
    font-size: 18px;
  }

  .abouts_contentStyle__3NGh4 {
    font-size: 12px;
  }
}

/* for screens smaller than 576px */
@media screen and (max-width: 575px) {
  .abouts_contentView__2L65a {
    width: 100%;
  }

  .abouts_textStyle__2YBAC {
    font-size: 18px;
  }

  .abouts_contentStyle__3NGh4 {
    font-size: 12px;
  }
}

.productview_card__3Z-95 {
  border-radius: 20px;
}

.productview_cardImg__3qIZI {
  object-fit: scale-down;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.productview_productContainer__1ZdQl {
  width: 100%;
}

.productview_productTitle__3Vq0K {
  font-size: 22px;
  font-weight: bold;
}

@media screen and (min-width: 1400px) {
  .productview_productContainer__1ZdQl {
    margin-top: 50px;
  }

  .productview_cardText__Oyiuh {
    font-size: 18px;
  }
}

/* for screens between 1201px and 1400px */
@media screen and (min-width: 1201px) and (max-width: 1399px) {
  .productview_productContainer__1ZdQl {
    margin-top: 50px;
  }

  .productview_cardText__Oyiuh {
    font-size: 18px;
  }
}

/* for screens between 993px and 1199px */
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .productview_productContainer__1ZdQl {
    margin-top: 50px;
  }

  .productview_cardText__Oyiuh {
    font-size: 18px;
  }
}

/* for screens between 769px and 992px */
@media screen and (min-width: 769px) and (max-width: 992px) {
  .productview_productTitle__3Vq0K {
    text-align: center;
  }

  .productview_cardText__Oyiuh {
    font-size: 18px;
  }
}

/* for screens between 576px and 768px */
@media screen and (min-width: 576px) and (max-width: 768px) {
  .productview_productTitle__3Vq0K {
    text-align: center;
  }

  .productview_cardText__Oyiuh {
    font-size: 18px;
  }
}

/* for screens smaller than 576px */
@media screen and (max-width: 575px) {
  .productview_productTitle__3Vq0K {
    text-align: center;
  }

  .productview_cardText__Oyiuh {
    font-size: 10px;
  }
}

