.hrStyle {
  border: none;
  height: 1px;
  background-color: #000;
}

.textStyle {
  font-weight: bold;
  font-family: "微軟正黑體";
}

.contentStyle {
  font-family: "微軟正黑體";
}

.informationView {
  width: 100%;
}

@media screen and (min-width: 1400px) {
  .textStyle {
    font-size: 28px;
  }

  .contentStyle {
    font-size: 18px;
  }

  .footerStyle {
    width: 75%;
  }
}

/* for screens between 1201px and 1400px */
@media screen and (min-width: 1201px) and (max-width: 1399px) {
  .textStyle {
    font-size: 28px;
  }

  .contentStyle {
    font-size: 18px;
  }

  .footerStyle {
    width: 75%;
  }
}

/* for screens between 993px and 1199px */
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .textStyle {
    font-size: 28px;
  }

  .contentStyle {
    font-size: 18px;
  }

  .footerStyle {
    width: 75%;
  }
}

/* for screens between 769px and 992px */
@media screen and (min-width: 769px) and (max-width: 992px) {
  .textStyle {
    font-size: 28px;
  }

  .contentStyle {
    font-size: 18px;
  }

  .footerStyle {
    width: 75%;
  }
}

/* for screens between 576px and 768px */
@media screen and (min-width: 576px) and (max-width: 768px) {
  .textStyle {
    font-size: 18px;
  }

  .contentStyle {
    font-size: 12px;
  }

  .footerStyle {
    width: 100%;
  }
}

/* for screens smaller than 576px */
@media screen and (max-width: 575px) {
  .textStyle {
    font-size: 18px;
  }

  .contentStyle {
    font-size: 12px;
  }

  .footerStyle {
    width: 100%;
  }
}
