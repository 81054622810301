.text {
  color: red;
}

@media screen and (min-width: 1400px) {
  .logoView {
    width: 150px;
  }

  .carousel {
    width: 80%;
  }

  .carouselImg {
    width: 1274px;
  }
}

/* for screens between 1200px and 1400px */
@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .logoView {
    width: 150px;
  }

  .carousel {
    width: 80%;
  }

  .carouselImg {
    width: 1274px;
  }
}

/* for screens between 576px and 1199px */
@media screen and (min-width: 576px) and (max-width: 1199px) {
  .logoView {
    width: 150px;
  }

  .carousel {
    width: 100%;
  }

  .carouselImg {
    width: 750px;
  }
}

/* for screens smaller than 576px */
@media screen and (max-width: 575px) {
  .logoView {
    width: 100px;
  }

  .carousel {
    width: 100%;
  }

  .carouselImg {
    width: 100vw;
  }
}
