.main {
  width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (min-width: 1400px) {
  .productPage {
    width: 78%;
    margin-top: 50px;
  }
}

/* for screens between 1201px and 1400px */
@media screen and (min-width: 1201px) and (max-width: 1399px) {
  .productPage {
    width: 78%;
    margin-top: 50px;
  }
}

/* for screens between 993px and 1199px */
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .productPage {
    width: 78%;
    margin-top: 50px;
  }
}

/* for screens between 769px and 992px */
@media screen and (min-width: 769px) and (max-width: 992px) {
  .productPage {
    width: 100%;
    margin-top: 30px;
  }
}

/* for screens between 576px and 768px */
@media screen and (min-width: 576px) and (max-width: 768px) {
  .productPage {
    width: 100%;
    margin-top: 30px;
  }
}

/* for screens smaller than 576px */
@media screen and (max-width: 575px) {
  .productPage {
    width: 100%;
    margin-top: 30px;
  }
}
