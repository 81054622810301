.sideMenu {
  width: 20%;
}

.sideMenuTitle {
  color: #8f8f8f;
  font-weight: bold;
}

.sideMenuItemText {
  font-weight: bold;
}
